import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

import system from './mod/system';
import user from './mod/user';

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    system,
    user
  }
})
