import {
    Button,
    Breadcrumb,
    BreadcrumbItem,
    Col,
    Card,
    Row,
    Carousel,
    CarouselItem,
    Divider,
    Form,
    FormItem,
    Input,
    Image,
    Link,
    Radio,
    Message,
    MessageBox,
    Select,
    Tag,
    Tabs,
    TabPane,
    Option,
    Result,
    Pagination,
    Checkbox,
    CheckboxGroup,
    DatePicker,
    Empty
} from "element-ui";

const element = {
    install: function (Vue) {
        Vue.use(Button);
        Vue.use(Breadcrumb);
        Vue.use(BreadcrumbItem);
        Vue.use(Col);
        Vue.use(Card);
        Vue.use(Divider);
        Vue.use(Row);
        Vue.use(Carousel);
        Vue.use(CarouselItem);
        Vue.use(Form);
        Vue.use(FormItem);
        Vue.use(Input);
        Vue.use(Image);
        Vue.use(Link);
        Vue.use(Result);
        Vue.use(Select);
        Vue.use(Tabs);
        Vue.use(TabPane);
        Vue.use(Option);
        Vue.use(Pagination);
        Vue.use(Tag);
        Vue.use(Checkbox);
        Vue.use(CheckboxGroup);
        Vue.use(Radio);
        Vue.use(Empty);
        Vue.use(DatePicker);
        Vue.prototype.$message = Message;
        Vue.prototype.$confirm = MessageBox.confirm;
    }
}

export default element;
