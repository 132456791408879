import axios from "axios";
import store from "@/store/index";

import { tansParams } from "@/utils/common";
import { Base64 } from "js-base64";

const clientId = "lamp_web_pro";
const clientSecret = "lamp_web_pro_secret";

const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 20000,
  crossDomain: true,
});

// 请求拦截器
request.interceptors.request.use(
  function (config) {
    // console.log("请求", config)
    if (config.data) {
      let url = config.url + "?" + tansParams(config.data);
      url = url.slice(0, -1);
      config.url = url;
    }

    let token = store.state.user.token;
    if (token) {
      config.headers["token"] = token;
    }
    config.headers["Authorization"] = `${Base64.encode(
      `${clientId}:${clientSecret}`
    )}`;
    //租户id
    config.headers["TenantId"] = "496397266211819520";
    if (localStorage.getItem("userInfo")) {
      config.headers["TenantId"] = JSON.parse(
        localStorage.getItem("userInfo")
      ).tenantId;
    }
    return config;
  },
  function (error) {
    if (err.toString().includes("Error: Request failed with status code 401")) {
      store.commit("logout");
      window.location.pathname = "/login";
    }
    return Promise.reject(error);
  }
);

// 响应拦截器
request.interceptors.response.use(
  function (response) {
    console.log("response===>", response);
    if (response.status === 200) {
      return response.data;
    }
  },
  function (error) {
    console.log("err===>", error);
    // if (error.code === 'ERR_BAD_REQUEST'){
    //     this.$message({message:error.response.data.msg || error.message  , type:'error'})
    // }
    return Promise.reject(error);
  }
);

export default request;
