var userInfo = window.localStorage.getItem('userInfo');
userInfo = userInfo ? JSON.parse(userInfo) : {};
import { reqGetUserInfo, reqAuthUserInfo } from "@/api/user";

const user = {
    state: {
        token: userInfo.token ? userInfo.token : '', //token
        //租户id
        tenantid: userInfo.tenantid ? userInfo.tenantid : '496397266211819520',
        userObj:{},
        authUserInfo: {}
    },
    mutations: {
        //登录
        login(state, data) {
            if (!data) return;
            localStorage.setItem("userInfo", JSON.stringify(data));
            state.token = data.token;
            state.tenantid = data.tenantid;
            reqGetUserInfo().then((res) => {
                console.log("用户信息=====>",res)
                localStorage.setItem('loginInfo', JSON.stringify(res.data))
            })
        },
        //退登
        logout(state) {
            state.token = ''
            state.tenantid = ''
            window.localStorage.removeItem('userInfo')
        },
        setAuthUserInfo(state, data){
            state.authUserInfo = data;
        },
        setKeyword(state, data){
            state.searchKeyword = data;
        }
    },
    actions:{
        // 通过Token获取用户信息
        async getAuthUserInfo({ commit }) {
            let res = await reqAuthUserInfo({});
            if (res.code === 0) {
                // 本地持久化用户信息
                console.log("用户信息", res)
                commit("setAuthUserInfo", res.data);
                localStorage.setItem('authUserInfo', JSON.stringify(res.data));
                return "ok";
            } else {
                return Promise.reject(new Error("faile"));
            }
        },

    }
}

export default user
