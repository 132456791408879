<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {

}
</script>

<style>
@import url('./assets/style/common.scss');
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50; */
}
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
a{
  color: #2d8cf0;
  background: 0 0;
  text-decoration: none;
  outline: 0;
  cursor: pointer;
  transition: color .2s ease;
}
a:hover{
  color:#00a0e8;
  text-decoration: underline;
}
.wrap{
  width: 1000px;
  height: 100%;
  margin: 0 auto;
}
</style>
