import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/icons/index';

// 按需引入elementUI
import 'element-ui/lib/theme-chalk/index.css'
import element from './utils/elementui'
Vue.use(element)

import Vant from 'vant';
Vue.use(Vant);

// 自定义类
import Utils from './utils/utils'
Vue.prototype.$utils = new Utils

Vue.config.productionTip = false
router.beforeEach((to, from, next) => {
  // 检查权限等
  next(); // 别忘了调用 next() 否则路由不会跳转
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
