import Vue from 'vue'
import svgIcon from '@/components/SvgIcon/svgIcon.vue'
Vue.component('svgIcon',svgIcon)   //  全局注册SvgIcon

const req = require.context('@/assets/icons/svg', false, /\.svg$/)
const requireAll = requireContext =>{
    // requireContext.keys()数据：['./404.svg', './agency.svg', './det.svg', './user.svg']
    requireContext.keys().map(requireContext)
    // console.log(requireContext.keys().map(requireContext))
}
requireAll(req)