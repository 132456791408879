import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // 首页
  {
    path: '/',
    name: 'home',
    component: () => import('../views/index/index.vue')
  },
  // 登录页
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/index.vue')
  },
  // 教材中心
  {
    path: '/bookcenter',
    name: 'bookcenter',
    component: () => import('../views/bookcenter/index.vue')
  },
  // 我的样书
  {
    path: '/mybook',
    name: 'mybook',
    component: () => import('../views/mybook/index.vue')
  },
  // 新闻动态
  {
    path: '/newsDynamic',
    name: 'newsDynamic',
    component: () => import('../views/news/index.vue')
  },
  //关于我们
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import('../views/aboutus/index.vue')
  },
  // 样书详情
  {
    path: '/bookDetails',
    name: 'bookDetails',
    component: () => import('../views/details/bookDetails.vue')
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/search/index.vue')
  },
  {
    path: '/dir',
    component: () => import('../views/details/dir.vue'),
    name: 'dir',
    meta: { Auth: true, title: '样书样章' }
  },
  {
    path: '/bt_dir',
    component: () => import('../views/details/bt_dir.vue'),
    name: 'bt_dir',
    meta: { Auth: true, title: '样书目录'}
  },
  {
    path: '/attach',
    component: () => import('../views/details/attach.vue'),
    name: 'attach',
    meta: { Auth: true, title: '资源下载'}
  },
  {
    path: '/sortview',
    component: () => import('../views/details/sortview.vue'),
    name: 'sortview',
    meta: { Auth: true, title: '资源预览'}
  },
  
  
  {
    path: '/message',
    name: 'message',
    component: () => import('../views/message/msg.vue'),
    meta: { Auth: true, title: '消息' }
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('../views/message/index.vue'),
    meta: { Auth: true, title: '消息测试' }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
