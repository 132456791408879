import request from "../utils/request";

// 获取用户信息
export const reqGetUserInfo = () => request({
    url: '/api/book/anyUser/app/user/loginInfo',
    method:'put'
})
//获取认证用户信息
export const reqAuthUserInfo = (data) => request({
    url: '/api/book/anyUser/app/user/authInfo',
    data: data,
    method: 'post'
})

// 添加老师的认证申请
export const reqTeacherAuth = (data) => request({
    url: "/api/book/anyUser/app/teacherAuthApply",
    data,
    method: 'post',
})

// 认证信息回显

export const getAuthInfo = (data) => request({
    url: "/api/book/anyUser/app/user/authInfo",
    data,
    method: 'post',
})


// 获取用户地址

export const reqUserAddress = (data) => request({
    url: "/api/book/anyUser/app/userAddress/page/self",
    data,
    method: 'post',
})

// 查看用户收藏书籍列表
export const ViewCollectbook = (data) => request({
    url: '/api/book/anyUser/app/bookFavorite/page',
    data,
    method: 'post'
})
